import { render, staticRenderFns } from "./HomepageTagging.vue?vue&type=template&id=405dde99&scoped=true"
import script from "./HomepageTagging.vue?vue&type=script&lang=js"
export * from "./HomepageTagging.vue?vue&type=script&lang=js"
import style0 from "./HomepageTagging.vue?vue&type=style&index=0&id=405dde99&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405dde99",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImgComponent: require('/app/components/common/ImgComponent.vue').default,ReusableLink: require('/app/components/common/ReusableLink.vue').default})
