
import processImageLink from '../../mixins/processImageLink.js'

export default {
  name: 'HomepageTagging',
  props: {
    data: {
      type: Object,
      default: () => ({
        Title: undefined,
      }),
    },
  },
  mixins: [processImageLink],
  data() {
    return { 
      activeItemId: 1
    }
  },
  computed: {
    activeCategroyKeywords() {
      return this.data.Categories?.find(item => item.CategoryId === this.activeItemId.toString() )?.CategoryKeywords
    }
  },
  methods: {
    toggleActiveItem(i) {
      if (i === this.activeItemId) {
        this.activeItemId = -1
      } else {
        this.setActiveItem(i)
      }
    },

    setActiveItem(i) {
      this.activeItemId = i
    },

    getRouteName() {
      return (this.$route.name).substring(0, (this.$route.name).indexOf('___'));
    },
    getEventTrackingLocationName() {
      if (this.getRouteName() === 'index')
        return 'homepage'
      if (this.getRouteName() === 'life-stage')
        return 'lifestage'
      if (this.getRouteName() === 'advisor-service')
        return 'broker'

      return 'other'
    },
    handleEventTracking(){
      const params = {}      
      params.categoryPrefix = `${this.getEventTrackingLocationName()}-`

      window.eventTracking({ 
        a:"open-whatsapp", 
        c: params.categoryPrefix + 'cta', 
        l:``, 
        cd:{ 
          "login-status":'NA'
        }, 
        save_action_entrypoint_with_label: false 
      })
    }

  },
}
